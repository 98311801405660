export const getDateDay = (date: any) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${year}-${month}-${day}`
}
// 返回 2022-12-19 00:00:00
export const getDateTime = (val: any) => {
  const date = new Date(val)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${year}-${month}-${day} 00:00:00`
}
/* 时间范围
* 传入['2023-03-13', '2023-03-15']
* 返回['2023-03-13 00:00:00', '2023-03-15 23:59:59']
*/
export const setScopeDate = (data: string[]) => {
  if (!Array.isArray(data)) return null
  const newDate = []
  newDate[0] = data[0] + ' 00:00:00'
  newDate[1] = data[1] + ' 23:59:59'
  return newDate
}
// 获取字符串中出现第n次的某个字符
export const findIndex = (name: any, chart: any, num: any) => {
  let x = name.indexOf(chart)
  for (let i = 0; i < num; i++) {
    x = name.indexOf(chart, x + 1)
  }
  return x
}
