export default {
  common: {
    accessDenied: '拒绝访问',
    pleaseSelect: '请选择',
    pleaseEnter: '请输入',
    StartDate: '开始日期',
    Pleaseenter: '请填写',
    EndDate: '结束日期',
    operations: '操作',
    detail: '详情',
    edit: '编辑',
    Cancel: '取消',
    Confirm: '确认',
    Next: '下一步',
    Search: '搜索',
    Reset: '重置',
    clear: '清空',
    save: '保存',
    saveAsDraft: '保存为草稿',
    save2: '确认无误',
    modify: '修改',
    see: '查看',
    back: '返回',
    upload: '上传',
    startDate: '开始日期',
    endData: '结束日期',
    toDate: '到',
    index: '序号',
    delete: '删除',
    exclusiveWaiter: '专属客服',
    dear: '尊敬的',
    colon: '：',
    comma: '，',
    customer: '客户',
    myRole: '您好! 我是您的专属客服',
    waiterDetail: '先生/小姐，将竭诚为您服务，谢谢！',
    sir: '先生',
    miss: '小姐',
    toDoService: '将竭诚为您服务,谢谢!',
    tell: '电话',
    QQ: 'QQ',
    mobilePhoneOrWechat: '手机/微信',
    view: '查看',
    status: '状态',
    noData: '暂无数据',
    noMessage: '暂无消息',
    noFile: '暂无文件',
    nofile: '暂无证书文件',
    downloadPDF: '下载PDF',
    operateSuccessfully: '操作成功',
    submit: '提交',
    agree: '同意',
    close: '关闭',
    tips: '提示',
    tipsText: '如有需要修改，请邮件/电话联系业务受理员',
    tipsText2: '是否确认证书草稿？',
    outLogin: '退出成功',
    exit: '退出',
    exitText: '确认是否退出当前用户？',
    AccountInformation: '账号信息',
    ChangePassword: '修改密码',
    LogOut: '退出登录',
    permission: '找不到页面',
    permissiontext: '打开了一个不存在的链接，请检查当前链接的可访问性。',
    BacktoHome: '返回首页',
    Relogin: '重新登录',
    BackPage: '返回上一页',
    DetailedIntroduction: '详细介绍',
    unknownError: '未知错误',
    confirmDelete: '确定要删除吗？',
    confirmDeleteAll: '确定要清除所有信息吗？',
    sure: '确定',
    nothing: '无',
    logo_tetx: '业务在线办理系统',
    perfect: '您的账户资料待完善，为保证审批流程顺利进行，请先完善账户资料，再发起申请并办理相关证书。',
    clickToPerfect: '点击完善 →',
    pleaseSelectCoun: '选择国家',
    pleaseSelectType: '选择类型',
    warning: '警告',
    clause: '服 务 通 用 条 款',
    cal1: '中创国际检验认证（深圳）有限公司(简称CIIC)（包括其所有关联公司，以下简称“本公司”)将根据并遵守此处包含的条款和条件（以下简称“本条件”）应要求向个人或机构提供服务.除非另有通知在公司与主要、服务之间达成的书面协议中，公司提供的服务应受本条件所有条款的约束并受其约束。',
    cal2: '1. 总则',
    cal3: '(a) 除非另有书面协议，或(i)和代表政府、政府团体或任何其它公众实体履行服务的管理法规不一致，或(ii)和当地法律的强制规定不一致，任何CIIC的关联公司或任何它们的代理(单称“公司”)和客户(“契约关系”)所有产生的合同关系，都受本服务通用条款(以下称为“通用条款”)约束。',
    cal4: '(b) 本公司可为发出指令的人或实体(私人、公众或政府)(以下称为“客户”)提供服务。',
    cal5: '(c) 除非本公司事先收到客户相反的书面指令，任何其它方都无权做出指令，特别是关于服务范围或提交做出的报告或证书(“结果报告”)方面的指令。无论是经客户指示或是依照环境、贸易惯例、作法或实践做出判断，客户在此不可撤销地授权本公司提交“结果报告”给第三方。',
    cal6: '2. 提供服务',
    cal7: '(a) 本公司根据经本公司确认的委托人的具体指令，以合理的审慎和技能提供服务。若无此指令，则根据：',
    cal701: '(1) 本公司的任何标准委托单或标准规格单中的条款；和/或',
    cal702: '(2) 任何有关的贸易惯例、作法或实践；和/或',
    cal703: '(3) 本公司认为在技术、操作和/或财务方面适当的方法。',
    cal8: '(b) 结果报告中陈述的信息来源于检验/测试的结果，执行检验/测试程序是根据客户的指令，和/或根据任何技术标准，贸易惯例或实践的结果评估，或应该被考虑的在我们专业建议中的其它情况。',
    cal9: '(c) 对样品检测后出具的结果报告仅仅反映本公司对该样品的评价，不反映对被抽取样品的一批货物的评价。',
    cal10: '(d) 如客户要求本公司见证任何第三方的工作，客户同意，本公司的唯一责任是在第三方工作时出现在现场并传递该结果或证实其工作中发生的事情。客户同意，本公司对第三方使用的设备、仪器和测量器具的状况和校准、所用的分析方法、第三方人员的资格、行为或疏漏，以及分析结果均不负责。',
    cal11: '(e) 本公司出具的结果报告只反映在工作当时所记录的事实，而且限于所收到指令的范围内，若无指令时，则限于所用的本条款2(a)中给出的可选择参照的范围。本公司没有责任涉及或报告所收到的专门指令或所用的可选择参照范围以外的事实或情况。',
    cal12: '(f) 本公司可委派代理或分包商承担全部或部分服务，客户授权本公司向代理或分包商提供其所承担服务的全部必要的信息。',
    cal13: '(g) 本公司如收到涉及客户和第三方签订的契约文件或第三方的文件，如销售合同、信用证、提单等，这些文件仅供参考用，而不扩展或限制经本公司接受的服务范围或职责。',
    cal14: '(h) 客户确认，本公司在提供服务时既不取代客户或任何第三方的位置，也不免除它们应负的任何职责，此外也不承担、不削减、不免除、不承诺解除客户对任何第三方或任何第三方对客户的任何责任。',
    cal15: '(i) 所有样品的保留期最长为3个月或样品性质允许的更短期限，到期后样品退给客户或由本公司自行处理，此后本公司终止对该样品的任何责任。样品存储期超 出3个月所产生的存储费由客户支付。如样品退给客户，由客户支付退运费用。如产生样品的特殊处理费用，由客户支付。',
    cal16: '3. 客户的责任',
    cal17: '客户要：',
    cal18: '(a)保证及时提供足够的信息、指令和文件(任何情况下不得晚于所要求的工作前48小时)，以便所要求的服务得以实施；',
    cal19: '(b) 为本公司的代表取得到达工作地点的所有必要的通行权，并采取一切必要的措施，消除或纠正服务实施中遇到的任何障碍或干扰；',
    cal20: '(c) 如有要求，根据服务实施的需要提供任何特殊设备和人员；',
    cal21: '(d) 无论本公司通知要求与否，要采取一切必要的措施，确保实施服务时的工作环境、场所和装置的平安和安全；',
    cal22: '(e) 对任何委托、样品或实验中包含的任何已知的实际或潜在危险或危害，如放射性、有毒、有害或爆炸元素或物质、环境污染或中毒的存在和危险，要事先通知本公司；',
    cal23: '(f) 按照和第三方的任何有关销售合同或其它合同及法律，全面行使全部权利和清偿全部债务。',
    cal24: '4. 收费和支付',
    cal25: '(a) 在本公司接受客户委托或合同磋商时未确定收费额的，应依照本公司的标准费率(有可能调整)，并且全部应交税款由客户支付。',
    cal26: '(b) 除发票上确定了更短期限外，客户应不晚于相关发票日期起30天，或本公司在发票上确定的期限(到期日)支付全部应付给本公司的费用。如未按时付款，则要按1.5%的月息(或在发票上确定的其它利率)支付自到期日起至(包括)实际收到付款日的利息。',
    cal27: '(c) 客户无权因声称对本公司的任何争端、反诉或抵销，而留置或延迟支付应付给本公司的任何款项。',
    cal28: '(d) 本公司可决定向任何有管辖权的法院就收取未付款提出诉讼。',
    cal29: '(e) 客户应支付本公司全部的收账费用，包括律师费和有关开支。',
    cal30: '(f) 一旦在实施服务过程中出现任何不可预见的问题和费用，本公司要尽力通知客户并有权收取附加费，以弥补完成该服务必需的额外时间和开支。',
    cal31: '(g) 如果因任何超出本公司控制的原因，包括客户失于履行它在上述第3条中的任何责任，本公司不能履行全部和部分服务时，本公司依然有权收取：',
    cal3101: '(1) 本公司发生的所有不可偿还费用的总合；和',
    cal3102: '(2) 按比例支付的等于实际上已实施的服务部分的约定费用。',
    cal32: '5. 服务的暂停和终止',
    cal33: '如出现以下情况，本公司有权立即且不承担任何责任地暂停或终止提供服务：',
    cal34: '(a) 客户失于履行任何他应尽的职责，而且在通知其过失后10天内客户不作补救；',
    cal35: '(b) 客户的任何暂停付款、与债权人做出安排、破产、无力偿付、破产管理或停业。',
    cal36: '6. 责任和赔偿',
    cal37: '(a) 责任范围',
    cal3701: '(1) 本公司既不是保险商也不是担保人，不承担这方面的任何责任。客户寻求保证不损失或不损害，应该适当投保。',
    cal3702: '(2) 结果报告的出具是以客户或其代表客户提供的信息、文件和/或样品为基础，并且仅仅是为了客户的利益，而且该客户应当对其在结果报告基础上所采取的其认为合适的行为负责。对任何根据该结果报告已采取或没采取的行动，对因提供给本公司不清楚、不正确、不完全、误导或虚假信息导致的任何不正确结果，无论本公司还是公司的任何官员、雇员、代理或分包商都不应为此对客户或任何第三方承担责任。',
    cal3703: '(3) 对因任何超出本公司控制的原因，包括客户失于履行他的任何责任而直接或间接导致的任何延期、部分或全部服务不能实施，本公司不承担责任。',
    cal3704: '(4) 本公司对任何性质和不管如何产生的损失、损害或费用的任何赔偿责任，在任何情况下都不超过付给发生索赔的该项具体服务的费用总额的十倍或二万美元(或等值本国货币)这两个金额中较少的一个。',
    cal3705: '(5) 本公司不负责任何间接或其他衍生性之损失，包括且不限于利润损失、业务损失、机会损失、商誉损失及产品召回之成本。本公司亦不负责可能由客户所造成之第三者的任何损失、损害或费用之索赔(包括且不限于产品责任之索赔)。',
    cal3706: '(6) 如有任何索赔，客户必须在发现所谓证明索赔的事实起30天内书面通知本公司，并且除非在自下述之日起的一年内提起诉讼，本公司在任何情况下都被免除对损失、损害或费用的所有索赔的全部责任：',
    cal37061: 'i. 发生索赔的服务被本公司实施的日期；或',
    cal37062: 'ii. 任何声称未实施的服务应完成的日期。',
    cal38: '(b) 赔偿：客户应保证、不伤害并赔偿本公司及其官员、雇员、代理和分包商，抵偿任何第三方提出的和与实施的、声称实施的、或未实施的任何服务有关的，无论是任何性质和无论如何发生的损失、损害或费用，包括全部法律开支和有关费用的全部索赔(实际的或要发生的)。',
    cal39: '7. 其它',
    cal40: '(a) 如发现本通用条款中的某一条和几条违法或在任何方面不能执行，这绝不影响或削弱其他条款的有效性、合法性和执行性。',
    cal41: '(b) 在提供服务的过程中和其后的一年内，客户不得直接或间接诱惑、怂恿或提出聘用本公司雇员，使其离开本公司的职位。',
    cal42: '(c) 未经本公司事先书面授权，不允许以广告宣传为目的使用本公司的名称和注册商标。',
    cal43: '8. 适用法律、管辖权与争议解决',
    cal44: '除非另行约定，所有产生的或与合约双方有关的争端都要受瑞士实体法的管辖，但不包括任何冲突法，而所有的争端应按国际商会的仲裁法则，由依据该法则规定指定的一个和多个仲裁员最终裁决。仲裁应在法国巴黎，使用英语进行。',
    cal45: '9. 特别条款',
    cal46: '除上第八条所述之外，如公司和客户的注册地在中国的，所有产生的或与合约双方有关的争端都要受中国实体法的管辖，但不包括任何冲突法，而所有的争端应按上海国际仲裁中心的仲裁法则，由依据该法则规定指定的一个和多个仲裁员最终裁决。仲裁应在上海，使用中文进行。',
    newMessage: '新消息',
    messageCenter: '消息中心',
    allRead: '全部已读',
    noMore: '到底了~',
    RMB: '人民币',
    dollar: '美元'
  },
  request: {
    401: '登录信息已过期，请重新登录',
    403: '拒绝访问',
    404: '请求错误,未找到该资源',
    405: '请求方法未允许',
    408: '请求超时',
    500: '服务器端出错',
    501: '网络未实现',
    502: '网络错误',
    503: '服务不可用',
    504: '网络超时',
    505: 'http版本不支持该请求',
    linkError: '连接错误',
    timeout: '服务器响应超时，请稍后重试',
    linkServerError: '连接服务器失败'
  },
  home: {
    hello: '你好！欢迎使用CIIC业务在线办理系统。',
    text1: '中创国际检验认证（深圳）有限公司（简称CIIC），为众多行业和产品提供通行全球一站式公共检测、认证、鉴定和检验服务平台解决方案。',
    text2: '申请操作指南 名词解释：',
    Myapplication: '我的申请',
    Mycertificate: '我的证书',
    Draftapplication: '申请草稿',
    text3: '退回的申请(需要修改或者补充信息的申请)',
    text4: '申请办理中',
    text5: '正常状态的证书',
    text6: '1个月内即将到期的证书',
    order: '笔',
    Terminology: '名词解释',
    Applicationstatus: '申请状态',
    ApplicationFormStatus: '申请单状态',
    Application1: '待受理：',
    Application1_2: '已提交，还未被受理的单',
    Application2: '退回：',
    Application2_2: '被退回，需要修改委托单或者补充信息',
    Application3: '处理中：',
    Application3_2: '办理中，正常执行流程',
    Application4: '已签发：',
    Application4_2: '已经签发证书，流程结束',
    Application5: '取消：',
    Application5_2: '确认已取消',
    Paymentstatus: '支付状态',
    Payment1: '待报价：',
    Payment1_2: '已被受理，受理方还未报价',
    Payment2: '待支付：',
    Payment2_2: '已报价，客户还未付款（包含已经在银行转账，还未被受理方财务确认）',
    Payment3: '部分支付：',
    Payment3_2: '付款后，受理方财务确认金额与委托单金额有差异',
    Payment4: '支付完成：',
    Payment4_2: '已完全结清',
    ApplicationStage: '申请阶段',
    stage1: '体系认证申请阶段',
    stage2: '出口认证申请阶段',
    stage3: '委托检验申请阶段',
    PendingApplications: '等待受理的申请',
    CertificationAcceptance: '认证受理',
    Typetest: '型式试验',
    FactoryInspection: '工厂检查',
    ConformityAssessment: '合格评定',
    CertificateIssuance: '证书签发',
    CertificateProduction: '证书制作',
    EndCertification: '认证结束',
    FieldInspection: '现场检验',
    LaboratoryTesting: '实验室检测'
  },
  myExpenses: {
    address: '地址',
    name: '姓名',
    date: '日期'
  },
  login: {
    AcountLogin: '账号登录',
    MobileLogin: '手机号登录',
    signIn: '登录',
    register: '注册',
    Forgetpassword: '忘记密码',
    userPlaceholder: '用户名',
    userError: '请输入用户名',
    PWPlaceholder: '请输入密码',
    PWError: '请输入密码',
    PWnum: '密码最少6位',
    Codelaceholder: '请输入验证码',
    CodeError: '请输入验证码',
    errorcode: '验证码错误',
    mobilePlaceholder: '手机号码',
    mobileError: '请输入手机号码',
    mobilenum: '输入11位手机号',
    smsPlaceholder: '短信验证码',
    smsError: '请输入短信验证码',
    smsGet: '获取验证码',
    smsSent: '已发送短信至手机号码',
    noAccount: '还没有账号?',
    createAccount: '创建新账号',
    wechatLoginTitle: '二维码登录',
    wechatLoginMsg: '请使用企业微信扫一扫登录',
    wechatLoginResult: '已扫描 | 请在设备中点击授权登录',
    logsuccess: '登录成功',
    mosuccess: '修改成功',
    wholeError: '请输入手机号或邮箱',
    third: '第三方登录',
    sweep: '支付宝扫一扫',
    WeChat: '微信',
    Alipay: '支付宝',
    remind: '您的支付宝账号暂未进行绑定系统账号，您可进行以下操作',
    Yes: '已有账号，去登录',
    No: '无账号，去注册',
    telResetPassword: '短信重置密码',
    emailResetPassword: '邮箱重置密码',
    emailsuccess: '邮箱验证码发送成功',
    firstgetCode: '请先获取验证码',
    PLemail: '请输入邮箱',
    emailCode: '邮箱验证码',
    prompt: '提示',
    getSMStips: '如若您收不到验证码，请先使用账号密码或邮箱登录后，在个人信息界面修改区号+手机号，便于您使用手机号快捷登入。'
  },
  myProfile: {
    username: '登录账号',
    headimg: '客户头像',
    name: '姓名',
    sex: '性别',
    customerIds: '客户身份',
    mobile: '手机号码',
    email: '邮箱',
    WeChatOrQQ: '微信',
    selectCountry: '国家',
    selectProvinceCityRegion: '省市区',
    addressDetail: '详细地址',
    english: '(英文)',
    postcode: '公司邮箱',
    companyName: '公司名称',
    businessLicense: '营业执照',
    corporate: '公司法人',
    companyPhone: '公司电话',
    companyFax: '传真',
    remark: '备注',
    introlebal: '公司简介',
    man: '男',
    woman: '女',
    Bailor: '委托人',
    Manufacturer: '制造商',
    Factory: '生产厂',
    Agency: '代理机构',
    editMyProfile: '修改我的资料',
    uploadFile: '拖拽文件到区域内或点击上传',
    uploadFormat: 'jpg/jpeg/png/pdf,最大20M',
    imgType: '图片必须是jpg/png/jpeg/gif/bmp格式',
    imgSize: '图片大小不能超过1MB',
    licenseType: '文件必须是jpg/png/jpeg/gif/pdf格式',
    licenseLength: '营业执照最多上传五个文件',
    licenseSize: '文件大小不能超过3MB!',
    validateUserName: '请输入登录名称',
    validateUserNameLength: '"登录名称"长度为2~16个字符',
    validateUserNameFormat: '"登录名称"必须为数字或英文',
    validateName: '请输入真实姓名',
    validateNameLength: '"真实姓名"长度为2~100个字符',
    validateNameFormat: '"真实姓名"必须为中文或英文',
    validateMobile: '请输入手机号码',
    validateMobileLength: '"手机号码"长度为8~30个字符',
    validateMobileFormat: '请输入正确的"手机号码"',
    validatePass: '请输入密码',
    validatePassLength: '密码长度为6位以上',
    validatePassFormat: '"密码"必须包含数字、字母、特殊符号!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~',
    validateEmail: '请输入邮箱',
    validateEmailFormat: '请输入正确的邮箱格式',
    validateZipCode: '请输入邮政编码',
    validateZipCodeFormat: '请输入4-6位数字的邮政编码',
    validateSex: '请输入性别',
    validateIdentity: '请输入客户身份',
    validateCountry: '请选择国家',
    validateProvince: '请选择省市区',
    validateAddress: '请输入地址详情',
    validateCompany: '请输入公司名称',
    validateCompanyLegal: '请输入公司法人',
    validateLicense: '请上传营业执照',
    completeMyInfo: '请完善账号信息内容',
    completeMyInfoTip: '请完善数据，完善后才能申办业务',
    Codelaceholder: '请输入验证码',
    Verificationcodesent: '已发送验证码',
    telcomfirm: '手机号验证',
    emailcomfirm: '邮箱验证',
    changeTel: '修改手机号',
    changeEmail: '修改邮箱',
    PLcomfirmWay: '请选择验证方式',
    newTel: '新手机号',
    telCode: '手机号验证码',
    emailbox: '邮箱',
    mobiletel: '手机',
    newEmail: '新邮箱',
    emailcode: '邮箱验证码',
    Scancodeverification: '扫码验证',
    hasPhoneCode: '已发送验证码到您的手机，请注意查收',
    getCode: '获取验证码',
    hasEmailCode: '已发送验证码到您的邮箱，请注意查收',
    firstgetCode: '请先获取验证码',
    changeItem: '更改密码、邮箱、手机号需要验证',
    password: '密码',
    PLinput: '请输入',
    change: '修改',
    personmessage: '联系人信息',
    identity: '身份证件',
    ID: '身份证',
    passport: '护照',
    IDnum: '身份证件',
    front: '正面',
    opposite: '背面',
    passportimg: '护照首页',
    supportsize: 'jpg/jpeg/png/pdf,最大20M',
    compouyinfo: '公司信息',
    compounenyNum: '统一社会信用代码',
    PLselect: '请选择',
    PLuploadfront: '请上传身份证正面照片',
    PLuploadopposite: '请上传身份证背面照片',
    PLuploadpassportimg: '请上传护照首页照片',
    acountInfo: '账号信息',
    register: '注册',
    Existingaccount: '已有账号？去登录',
    Login: '登录',
    Pleasefill: '请填写渠道商信息，填写信息保存后，即可申办业务',
    confirmpassword: '确认密码',
    Available: '可用于登录',
    notnameNumber: '名字不能全为数字',
    PLidNumber: '请输入身份证号',
    regitererr: '注册失败',
    Contact: '联系人信息',
    WechatOther: '微信/WhatsApp/其他',
    PLtel: '请输入号码',
    PLidNo: '请输入证件号',
    idpicture: '证件照片',
    companynum: '公司人数',
    companywebsite: '公司网址/官网',
    companyquality: '公司各资质人数',
    PLselecttype: '请选择类型',
    add: '新增',
    delete: '删除',
    companysize: '公司资质',
    // 公司简介
    companyintro: '公司简介',
    // 来源
    source: '来源',
    // WSCT官网
    WSCT: 'CIIC官网',
    // 网络
    network: '网络',
    // WSCT员工介绍
    WSCTintro: 'CIIC员工介绍',
    // 请输入介绍人名字
    intro: '请输入介绍人名字',
    // 其他
    other: '其他',
    // 请选择身份证件
    selectid: '请选择身份证件',
    // 请上传证件照片
    uploadid: '请上传证件照片',
    // 请输入证件号码
    inputid: '请输入证件号码',
    // 请上传身份证件首页
    uploadidcard: '请上传身份证件首页',
    // 请选择资格证书
    selectcertificate: '请选择资质类型',
    // 请选择资格名称
    selectcertificatename: '请选择资质名称',
    // 请输入人数
    inputnum: '请输入人数',
    // 请输入公司总人数
    inputcompanynum: '请输入公司总人数',
    // 请选择公司资质
    selectcompany: '请选择公司资质',
    // 请输入公司简介
    inputcompanyintro: '请输入公司简介',
    // WSCT员工介绍
    inputWSCTintro: '请输入介绍员工名称',
    // 不能输入介绍员工名称
    inputWSCTintroError: '不能输入介绍员工名称',
    // 文件类型错误
    fileTypeError: '文件类型错误',
    // 文件大小错误
    fileSizeError: '文件大小错误',
    // 请选择资质证书
    selectcertificateError: '请选择公司资质',
    // 长度需小于50
    lengthError: '长度需小于50',
    // 请输入来源
    PLsource: '请输入来源',
    // 请输入账号
    account: '请输入账号',
    // 请输入真实名字
    realname: '请输入真实名字',
    // 请输入邮箱
    plemail: '请输入邮箱',
    // 请输入统一社会信用代码
    unifiedsocial: '请输入统一社会信用代码',
    // 请输入公司名称
    companyname: '请输入公司名称',
    // 请输入法人姓名
    legalperson: '请输入法人姓名',
    // 请输入传真
    fax: '请输入传真',
    // 请输入地址
    address: '请输入地址',
    // 请输入邮编
    zipcode: '请输入邮箱',
    // 请输入公司人数
    companyallnum: '请输入公司人数',
    // 请输入网址
    website: '请输入网址',
    // 请输入微信/QQ
    wechatqq: '请输入微信',
    // 请输入身份证号
    idcard: '请输入身份证号',
    // 请输入备注
    remarks: '请输入备注',
    // 请输入正确的网址
    websiteError: '请输入正确的网址',
    // 注册信息确认
    registerInfo: '注册信息确认',
    person: '人',
    // 请输入密码
    plepassword: '请输入密码',
    // 渠道类型
    channelType: '渠道类型',
    // 输入公司关键词点击回车搜索公司
    searchCompany: '输入公司关键词搜索公司',
    // 请选择渠道类型
    plechannelType: '请选择渠道类型',
    // 公司
    company: '公司',
    // 个人
    personal: '个人',
    // WSCT渠道加盟
    WSCTchannel: 'CIIC渠道加盟',
    // 咨询热线 0755-269966192 26992306 26996053(海外)
    consult: '咨询热线 0755-26996192 26992306 26996053',
    // 微信13823277946
    wechattel: '手机号/微信13823277946',
    // 非凡事业，立即启航！
    start: '非凡事业，立即启航！',
    // 请输入身份证件号码
    pleidcard: '请输入身份证件号码',
    selectChannelTypea: '请选择渠道类型',
    // 请输入公司电话
    plecompanyphone: '请输入公司电话',
    // 请选择手机所属国家区号
    plecountry: '请选择手机所属国家区号',
    // 亲输入公司联系人电话
    plecontactphone: '请输入公司联系人电话',
    // 个人资质
    personalqualification: '个人资质',
    // personalintro
    personalintro: '个人简介',
    // personalresume
    personalresume: '个人简历',
    // clickOrDrag
    clickOrDrag: '点击或拖拽上传',
    // uploadFileType
    uploadFileType: '上传格式为doc/docx/pdf,大小不得超过10MB',
    // pleprovince
    pleprovince: '请输入省市区',
    // componyhumantel
    componyhumantel: '法人代表手机号',
    // upload
    upload: '上传',
    // 请输入验证码
    plecode: '请输入验证码',
    // 请输入公司电话
    plecompanytel: '请输入公司电话',
    plecompanyemail: '请输入公司邮箱',
    // plecompanywebsite
    plecompanywebsite: '请输入公司网址',
    // pleuploadcertificate
    pleuploadcertificate: '请上传资质图片',
    // plequalification
    pleequalqualification: '请完善已选择的个人资质项',
    // pleuploadresume
    pleuploadresume: '请上传个人简历',
    // uploadlimit
    uploadlimit: '超过上传限制',
    // preview
    preview: '预览',
    // plecompleteprovince
    plecompleteprovince: '请完善省市区',
    // companynamelength
    companynamelength: '公司名称不能少于5个字'
  },
  register: {
    Accountname: '账号名称',
    Accountlaceholder: '请输入名称',
    nameError: '请输入名称',
    Password: '密码',
    PWPlaceholder: '请输入密码',
    PWError: '请输入密码',
    Passwords: '确认密码',
    PWPlaceholders: '请再次输入密码',
    PWErrors: '请再次输入密码',
    inconsistent: '两次输入密码不一致',
    Name: '真实姓名',
    Namelaceholder: '请输入真实姓名',
    NameError: '请输入真实姓名',
    mobile: '手机号',
    mobilePlaceholder: '请输入手机号码',
    mobileError: '请输入手机号码',
    email: '邮箱',
    emailPlaceholder: '请输入邮箱',
    emailError: '请输入邮箱',
    read: '已阅读并同意',
    readError: '请阅读并同意协议',
    agreement: '《服务条款》',
    Backlogin: '返回登录',
    register: '注册',
    Resuccess: '注册成功',
    pleaseSelectMethod: '请选择一种方式验证',
    moblieVerificationCode: '手机验证码',
    emailVerificationCode: '邮箱验证码',
    moblieVerification: '手机号验证',
    emailVerification: '邮箱验证',
    pleaseInputCode: '请输入验证码',
    pleaseGetCode: '请先获取验证码',
    pleaseTrueMobile: '请输入正确的手机号',
    alreadyAccount: '已有账号？去登录'
  },
  setting: {
    oldPassword: '旧密码',
    oldPWPlaceholder: '请输入旧密码',
    oldPWError: '请输入旧密码',
    Password: '新密码',
    PWPlaceholder: '请输入密码',
    PWError: '请输入密码',
    Passwords: '确认密码',
    PWPlaceholders: '请再次输入密码',
    PWErrors: '请再次输入密码',
    inconsistent: '两次输入密码不一致',
    changePassword: '修改密码',
    Modificationsuccess: '修改成功',
    PLresetWay: '请选择验证方式',
    phone: '手机',
    telCode: '手机号验证码',
    hastelCode: '已发送验证码到您的手机，请注意查收',
    getCode: '获取验证码',
    email: '邮箱',
    emailCode: '邮箱验证码',
    hasemailCode: '已发送验证码到您的邮箱，请注意查收',
    PLinput: '请输入',
    tel: '手机号验证',
    emailbox: '邮箱验证',
    hasCode: '已发送验证码',
    firstgetCode: '请先获取验证码'
  },
  // 申请办理情况
  rfclist: {
    Number: '序号',
    HSCode: 'HSC编号',
    ApplicationFor: '证书类型',
    Title: '标题',
    TitlePlaceholder: '请输入标题',
    Importer: '进口商',
    ImporterPlaceholder: '请输入进口商',
    Exporter: '出口商',
    ExporterPlaceholder: '请输入出口商',
    Status: '状态',
    CreatedTime: '创建时间',
    PaymentStatus: '付款状态',
    Quotation: '报价单',
    QuotationPreview: '报价单预览',
    QuotationConfirmation: '报价确认',
    PlaceOfInspection: '验货地址',
    RFCNo: 'RFC No.',
    DraftCertificate: '证书草稿',
    DraftPreview: '拟稿预览',
    DraftReview: '拟稿确认',
    OthersAction: '其他操作',
    Keywords: '关键词',
    KeywordsPlaceholder: '请输入关键词',
    Country: '国家',
    ViewQuotation: '查看报价单',
    Preview: '预览',
    Totalamount: '总金额',
    AmountPaid: '已支付',
    Unpaid: '未支付',
    AccepterEmail: '受理人/邮箱',
    DraftConfirmation: '草稿确认',
    DraftView: '草稿查看'
  },
  // 我的证书
  certificate: {
    Number: '序号',
    RFCNo: 'RFC No.',
    CertificateNo: '证书编号',
    Title: '标题',
    Importer: '进口商',
    Exporter: '出口商',
    CertificateStatus: '证书状态',
    ApplicationFor: '证书类型',
    IssueDate: '签发日期',
    Route: 'Route',
    CreatedTime: '创建日期',
    CertificateType: '证书类型',
    Action: '操作',
    Status: '状态',
    CertificateDetails: '证书详情',
    CompanyName: '公司名称'
  },
  customsClearance: {
    applicationDrafts: {
      title: '标题',
      exporter: '出口商',
      applicationFor: '申请类型',
      importer: '进口商',
      placeOfInspection: '检验地点',
      createdTime: '创建时间',
      certificateType: '证书类型'
    },
    myApplicationForm: {
      Select: '请选择对应的业务',
      country: '国家',
      createMode: '创建方式',
      createForm: '创建表单',
      importApplicationData: '导入历史申请数据',
      choose: '选择',
      pleaseChoose: '请点击按钮进行选择',
      title: '标题',
      applicationFor: '申请类型',
      hasIDFNumber: '是否已获得IDF号码',
      IDFNo: 'IDF No.',
      UcrNo: 'UCR No.',
      mailCertificateCollection: '邮件证书领取',
      shipmentMethod: '运输方式',
      cargoType: '出运方式',
      containerInfo: '货柜信息',
      placeOfLoading: '装运港',
      placeOfDischarge: '卸货港',
      countryOfSupply: '供应国',
      countryOfOrigin: '起运国',
      quantityDelivered: '交付量',
      full: '全部',
      partial: '部分',
      tel: '电话',
      fax: '传真',
      qq: 'QQ',
      email: '邮箱',
      hotline: '大客户热线手机/微信',
      address: '地址',
      contactInfo: '如需办理此业务，请通过以下方式联系我们',
      addressDetail: '广东省深圳市宝安区应人石路口宝石科技园A-B'
    },
    productConformity: {
      placeOrdersOnline: '在线下单，快捷管理订单',
      draftsDetailTitle: '申请草稿详情',
      statusDetailTitle: '申请办理情况详情',
      businessGuide: '业务申请指南',
      applicationGuide: '申请指南',
      applicationGuideDownload: '申请指南下载',
      importantNote: '重要提示: 此表格必须在货物装运前填写并提交给CIIC',
      FillOutTheFormOnlineHint: '在线填写表单或调用历史数据或下载模板填写后再提交',
      basicContentsForm: '验货申请单基础内容',
      uploadAttachmentAndInvoice: '上传检验申请表所需形式发票和附件',
      uploadAttachmentAndInvoice2: ' 检验申请表所需形式发票和附件',
      uploadAttachment: '上传检验申请表所需附件',
      uploadAttachment2: '检验申请表所需附件',
      pleaseEnterParties: ' 请输入各贸易方信息',
      pleaseEnterParties2: ' 各贸易方信息',
      finalInvoice: '最终发票或形式发票',
      itemsSending: '添加出货产品的信息（完整申报所有产品信息）',
      itemsSending2: '出货产品的信息（完整申报所有产品信息）',
      createType: '创建类型',
      selectFormTypeTitle: '参考信息',
      applicationFormTitle: '填写申请表基本内容',
      titlePrompt: '就是您给自己的申请起的名字，便于您查询和区分不同的申请，两个申请的申请标题不能重复。 您可以采用便于自己统计和查询的方式命名，不宜太长，举例： 1）公司名称缩写-产品缩写-编号；2） 商标-日期-编号 。',
      applicationFormTitle2: '申请表基本内容',
      UploadTitle: '验货申请单所属形式发票上传',
      downloadTemplate: '下载模板',
      import: '导入',
      saveDraft: '保存为草稿',
      addInvoice: '新增发票',
      invoiceText: '发票',
      invoiceRemind: '必须添加至少一个发票信息',
      upload: '上传',
      invoiceFile: '发票文件',
      invoiceNO: '发票号码',
      invoiceDate: '发票时间',
      currency: '币种',
      amount: '总金额',
      incoterm: '国际贸易术语',
      uploadText: '点击或者拖拽文件',
      upload2: '上传',
      uploadTip: '上传格式为jpg/gif/png/bmp/doc/docx/xlsx/xls/pdf/txt/zip/tar并且大小不超过30MB',
      uploadDocument: '上传附件',
      documentText: '附件',
      documentTip: '装箱单 / IDF文件 / 质量文件 / 其他文件',
      documentMessage01: '1、上传的每个附件大小不超过30M;否则将无法正常提交。',
      documentMessage02: '2、支持的文件类型:jpg、gif、png、bmp、doc、docx、xlsx、xls、pdf、txt、zip、tar。',
      fileType: '文件类型',
      fileName: '文件名称',
      size: '大小',
      remarks: '备注',
      companyName: '公司名称',
      companyNameEn: '公司名称(英文)',
      country: '国家',
      city: '城市',
      address: '地址',
      zipCode: '邮编',
      contactName: '联系人',
      telephone: '电话号码',
      telephonePlaceholder: '示例：+86-10-8888888 或 86-010888888',
      email: '邮箱',
      fax: '传真',
      payWSCTService: '支付CIIC服务费用',
      exporterDetails: '卖家/出口国详情',
      placeOfInspection: '检验地点',
      chooseHistoricalData: '选择历史数据',
      importerDetails: '买家/进口国详情',
      warehouseName: '仓库名称',
      warehouseNameEn: '仓库名称(英文)',
      zoneProvince: '区域/省份',
      GoodsAvailableDate: '预计检验日期',
      pleaseSelectRfcType: '请先选择申请类型',
      beneficiary: '受益方',
      addProduct: '添加产品',
      HSCode: '海关编码',
      brand: '品牌',
      modelNumber: '型号',
      quantity: '数量',
      unitPrice: '单价',
      unit: '单位',
      goodsDescription: '货品描述',
      manufacturerName: '制造商名称',
      manufacturerAddress: '制造商地址',
      countryOfOrigin: '原产国',
      goodsCondition: '货物状况',
      assemblyState: '组装状态',
      ApplicableStandard: '适用标准',
      brOfRegistration: '可提供证书',
      correspondingCertificate: '对应注册证书（SOR）/许可证书（SOL）/产品证书（PC）号码',
      ClearAllItems: '清除所有信息',
      getTemplate: '下载模板',
      importItems: '导入数据',
      productList: '产品列表',
      uploadProductTip: '导入数据文件格式为xlsx并且大小不超过20MB',
      containerSize: '货柜尺寸',
      containerQuantity: '货柜数量',
      applyTypeItem: '请选择申请类型',
      getIdfNo: '请选择是否已获得IDF号码',
      idfNo: '请输入IDF No.',
      ucrNo: '请输入UCR No.',
      needCertPerson: '请选择邮件证书领取',
      transportMode: '请选择运输方式',
      packingMethod: '请选择出运方式',
      containerData: '请输入货柜信息',
      supplierCountry: '请选择供应国',
      selectCorrectData: '请选择正确的',
      // 发票信息
      invoicePath: '请上传发票',
      invoiceNo: '请输入发票号码',
      selectInvoiceDate: '请选择发票时间',
      selectTradeTerm: '请选择国际贸易术语',
      selectCurrency: '请选择币种',
      invoiceTotal: '请输入总金额',
      article: '第',
      uploadInvoiceError: '条发票文件上传失败',
      // 附件信息
      selectFileType: '请选择文件类型',
      pleaseUploadFile: '请上传附件',
      // 详细信息
      inputCompanyName: '请输入公司名称',
      inputCompanyNameEn: '请输入公司名称（英文）',
      inputWarehousename: '请输入仓库名称',
      inputWarehousenameEn: '请输入仓库名称(英文)',
      countryName: '请输入国家',
      cityName: '请输入城市',
      inputAddress: '请输入地址',
      inputContact: '请输入联系人',
      inputTelephone: '请输入电话号码',
      selectPostDate: '请选择预计检验日期',
      inputEmail: '请输入邮箱',
      inputEmailReg: '请输入正确的邮箱格式',
      inputTelephoneReg: '格式为4-20位的数字、特殊字符+-',
      inputRegionOrPrice: '请输入区域/价格',
      // 产品信息
      inputHSCode: '请输入海关编码',
      inputBrandName: '请输入品牌',
      inputModel: '请输入型号',
      inputQuantity: '请输入数量',
      inputPrice: '请输入单价',
      selectUnit: '请选择单位',
      inputGoodsDescription: '请输入货品描述',
      inputManufacturerName: '请输入制造商名称',
      inputManufacturerAddress: '请输入制造商地址',
      selectOriginCountry: '请选择原产国',
      selectFreightStatus: '请选择货物状况',
      selectAssembleStatus: '请选择组装状态',
      selectHistory: '选择历史申请数据',
      importExcel: '上传格式为xlsc并且大小不超过20MB',
      noCountryText: '未查询到国家信息',
      applicationDonotEdit: '当前认证申请书状态不可编辑,请联系业务员进行操作',
      addApplicationSaveSuccess: '自动保存成功',
      addApplicationSaveError: '自动保存失败',
      addApplicationSubmitSuccess: '提交成功',
      addApplicationSubmitError: '提交失败',
      addApplicationDraftsSuccess: '保存草稿成功',
      addApplicationDraftsError: '保存草稿失败',
      completeApplicationContent: '请完善认证申请书必填内容',
      lessAddOneProduct: '至少添加一个产品项目',
      pleaseAddInvoice: '请添加发票信息',
      improvedocumentInfo: '请输入附件必填信息',
      upload20Attachments: '温馨提示：最多上传二十个附件！',
      selectHistoryData: '请选择历史申请数据',
      selectApplyType: '请选择申请类型',
      pleaseUpdateTemplate: '请导入Excel模板',
      whetherInspect: '是否远程验货',
      pleaseSaveOrEdit: '请保存或删除编辑状态的附件信息',
      maxInvoiceText: '最多上传三个发票信息',
      currentApplicationCannotEdit: '当前认证申请书状态不可编辑,请联系业务员进行操作',
      timeCannotEarlier: '时间不能早于当前日期',
      timeCannotToday: '时间不能超过当前日期',
      pleaseImproveProduct: '请完善产品信息：',
      limitFilesNum: '每次最多只能上传5个附件',
      sureSaveDraft: '确定存入草稿吗？',
      uploadSuccess: '上传成功',
      uploadError: '上传失败',
      toModify: '去修改',
      haveFailDocument: '个附件正在上传或上传失败，是否确定提交？',
      reupload: '重新上传',
      excelImport: 'Excel导入',
      // 运输信息
      packagingInformation: '包装信息',
      packageQuantity: '包装数量',
      transportContainerNumber: '运输柜号',
      acid: 'ACID',
      importersTaxNumber: '进口商税号',
      exporterID: '出口商ID',
      exportCountry: '出口国家',
      exportCountryCode: '出口国家代号',
      limitContainerNo: '最多只能添加10个运输柜号',
      limitContainerNoItem: '运输柜号为字母和数字并且长度不超过20',
      pleaseInputTransportNum: '请输入运输柜号，按Enter键添加',
      pleaseInputacid: '请输入ACID',
      pleaseSelectpackaging: '请选择包装信息',
      pleaseInputPackageNum: '请输入包装数量',
      pleaseInputImportersTax: '请输入进口商税号',
      pleaseInputExportID: '请输入出口商ID',
      pleaseSelectExportCode: '请选择出口国家代号',
      inputAfterEnter: '输入后按Enter键添加',
      // 加载
      loading: '加载中...',
      submitting: '提交中...',
      uploading: '上传中...',
      // 用户条款
      userTerms: '用户条款',
      companyAddress: '，地址，',
      ourCompany: '我司，',
      userTermsStated: '，在此特别申明，本次申报的产品不受召回产品的影响，也不是不合格品或假冒伪劣产品，我们对所有申报信息的真实性承担责任。 同时，我们也深知进口国对这些产品进口的监管要求并承诺遵守这些要求。',
      userTermsInfo: '提交信息后，CIIC会尽快受理申请单并且邮件联系您，请确认是否此邮箱',
      usreTermsInfo2: '能联系到您，如不是，请在下方填写，多个邮箱以“；”隔开。'
    },
    applicationSuccess: {
      returnIndex: '返回首页',
      submittedForReview: '已提交审核',
      reapply: '再次申请',
      viewApplicationRecord: '查看申请记录'
    },
    exportProductCertification: {
      mailCertificateCollection: '邮件证书领取',
      businessInfo: '商家信息',
      importer: '进口商',
      exporter: '出口商',
      manufacturer: '制造商',
      placeOfSurvey: '检验地点',
      VATNumber: '增值税号',
      paymentInfo: '付款信息',
      transportInfo: '运输信息',
      dateOfShipment: '运输日期',
      loadingType: '装载类型',
      loadingPort: '装货港',
      dischargingPort: '卸货港',
      productInvoice: '产品发票',
      uploadProductInvoice: '上传产品发票',
      invoiceInfo: '发票信息（最终发票或形式发票）',
      noData: '无',
      accessoriesOfAProduct: '产品附件',
      updateAccessoriesOfAProduct: '上传产品附件',
      saveToDraft: '存入草稿',
      oneClickTop: '一键置顶'
    }
  },
  particular: {
    dwarTitle: '出口认证与产品认证',
    businessIntroduction: '业务介绍',
    exportcertification: '出口认证',
    text1: '出口产品清关认证检测',
    text2: '出口认证是对产品的认证。为了保证进口产品的质量，越来越多的国家不断出台更新进口产品相关的法律法规，要求进出口商在装运前委托第三方检验认证机构对产品进行检验，对符合进口国标准的产品出具资料，使货物在进口国顺利通关，否则无法进口。',
    text3: '因此更多国家开始实施“符合性评定方案”(即PVoC, CoC等清关认证)，作为可持续性发展计划的一部分。他们颁发的证书名称略有不同，但实际上几乎相同。把它们理解为通关就够了。例如，肯尼亚(清关认证)、博茨瓦纳(清关认证)、埃及(称为检验证书)、尼日利亚(称为SONCAP证书，或SC)、乌干达(通关)。',
    text4: 'CIIC贸易及机构服务将凭借专业的团队，丰富的产品符合性评定经验，协助并为广大出口企业提供广泛、高效和量身定制的解决方案，以确保出口商的货物符合进口国适用的标准和法规要求, 助您的产品顺利清关。',
    mainProcess: '主要流程',
    text5: '向检验和认证机构(如CIIC)提供信息以供审查并预约检验。',
    text6: '就提供的具体信息而言，不同国家和出口国之间可能有一些差异，但总体上是相似的。例如:出口尼日利亚:委托书(机构提供模板供客户填写)、装箱单、、检测报告(具体检测报告接受什么标准，如何与机构确定报告有效期)、表格；出口肯尼亚:委托书(机构提供给客户填写的模板)、装箱单、、检测报告(具体检测报告接受什么标准，如何与机构确定报告有效期)、IDF。',
    text7: '批准后安排检查。',
    text8: '货物生产和包装后，检查员可以检查货物。在这个过程中，检验员会对产品包装进行检查，随机打开几个盒子对里面的产品进行检查，拍照并将检查报告发回公司审查。',
    text9: '签发通关。',
    text10: '经检验，如有整改意见，出口商应在装运前进行整改。如无补正意见，出口商应出具，供出口商核查。如无差错，出口商应将正本送交目的港收货人办理通关手续。'
  },
  policyInfo: {
    policy: '政策法规'
  },
  messageList: {
    title: '标题',
    NoticeType: '消息类型',
    Readornot: '是否已读',
    Date: '日期',
    Messagedetails: '消息详情',
    center: '消息中心',
    Acceptor: '受理人：',
    Data: '日期：',
    Read: '已读',
    Unread: '未读',
    more: '更多'
  },
  publicFile: {
    title: '公开发文件下载列表'
  },
  // 产品检测申请
  Electronics: {
    productName: '名称',
    code: '编码',
    placeOrdersOnline: '在线下单，快捷管理订单',
    DetectionCategory: '检验类别',
    name: '电子电器产品测试  新申请',
    WSCTname: 'CIIC在下列产品范围内能够从事认证业务，请选择您的产品',
    FillOutTheFormOnlineHint: '在线填写表单或调用历史数据后提交',
    draftsDetailTitle: '申请草稿详情',
    statusDetailTitle: '申请办理情况详情',
    saveDraft: '保存为草稿',
    reportingRequirement: '报告要求',
    reportForm: '报告形式：',
    reportFormtips: '请选择报告形式',
    reportCollection: '报告领取：',
    productRelatedInformation: '产品相关信息',
    Title: '标题',
    ProductNameCN: '产品名称（中文）',
    ProductNameEN: '产品名称（英文）',
    productCategory: '产品类别',
    ProductNameENtips: '请输入产品名称',
    sampleQuantity: '样品数量',
    SampleReturnOrNot: '样品是否返回',
    ModelSpecificationCN: '型号规格(中文)',
    ModelSpecificationtips: '请输入型号规格',
    ModelSpecificationEN: '型号规格(英文)',
    Brand: '商标',
    uploadAttachment: '上传附件',
    attachment: '附件',
    uploadtips: '点击上传   原理图 / PCB图 / 产品说明书 / 变压器规格书 / 型号差异说明 / 合同 / 营业执照/其他',
    fileType: '文件类型',
    fileName: '文件名称',
    Siez: '大小',
    State: '状态',
    Remake: '备注',
    Operate: '操作',
    textone: '每个上传的附件大小不超过30M;否则将无法正常提交',
    texttwo: '支持的文件类型为:jpg、gif、png、bmp、doc、docx、xlsx、xls、pdf、txt、zip、rar。',
    Yes: '是',
    No: '否',
    NoWSCT: '否(CIIC自行处理)',
    certificationProject: '认证项目',
    detectionType: '（检测类型）',
    cProjectError: '请选择认证项目',
    allParties: '各方基本信息',
    Applicant: '申请商',
    applicant: '委托人（持证方）',
    manufacturer: '制造商',
    factory: '生厂商',
    companyCN: '公司名称(中文)',
    companyENError: '请输入公司名称',
    companyEN: '公司名称(英文)',
    siteCN: '地址(中文)',
    siteENError: '请输入地址',
    siteEN: '地址(英文)',
    contactName: '联系人',
    contactNameError: '请输入联系人',
    telephone: '电话',
    telephoneError: '请输入电话',
    telephone11Error: '不能少于四位',
    telephone12Error: '只能由数字、短划线(-)和加号(+)组成，4~20个字符之间',
    email: '邮箱',
    emailError: '请输入正确的邮箱',
    telephonePlaceholder: '示例：+86-10-8888888 或 86-010888888',
    fax: '传真',
    chooseHistoricalData: '选择历史数据',
    identicalApplicant: '同委托人',
    else: '其他',
    serviceRequest: '服务要求',
    serviceRequestErr: '请输入服务要求其它时间',
    reportproductType: '请选择产品类型',
    // 用户条款
    userTerms: '用户条款',
    companyAddress: '，地址，',
    ourCompany: '我司，',
    userTermsStated: '，在此特别申明，本次申报的产品不受召回产品的影响，也不是不合格品或假冒伪劣产品，我们对所有申报信息的真实性承担责任。 同时，我们也深知进口国对这些产品进口的监管要求并承诺遵守这些要求。',
    userTermsInfo: '提交信息后，CIIC会尽快受理申请单并且邮件联系您，请确认是否此邮箱',
    submitSuccess: '提交成功',
    submitError: '提交失败',
    SaveSuccess: '自动保存成功',
    SaveError: '自动保存失败',
    DraftsSuccess: '保存草稿成功',
    DraftsError: '保存草稿失败',
    titleTips: '就是您给自己的申请起的名字，便于您查询和区分不同的申请，两个申请的申请标题不能重复。 您可以采用便于自己统计和查询的方式命名，不宜太长，举例： 1）公司名称缩写-产品缩写-编号；2） 商标-日期-编号 。',
    // 草稿
    DetectionType: '检测类型',
    ApplicationCategory: '申请类别',
    warHis: '请选择历史数据',
    OTHERWing: '请填写其他项',
    // 化学产品
    chemistry: {
      SampleInformation: '样品信息',
      SampleName: '样品名称',
      SampleNameErr: '请输入样品名称',
      SampleNameCN: '样品名称（中文）',
      SampleNameEN: '样品名称（英文）',
      Model: '型号',
      ModelErr: '请输入型号',
      ModelCN: '型号（中文）',
      ModelEN: '型号（英文）',
      MatchCode: '样品批号',
      batchNumber: '批号',
      materials: '材料',
      materialsErr: '请输入材料',
      TestSite: '测试部位',
      Brand: '商标',
      Bases: '样品参考信息/主要成份',
      TestItem: '检测项目',
      Applicationtestitems: '申请检测项目',
      ApplicationtestitemsErr: '输入/选择申请检测项目',
      TestItemON1: '测试项目与要求：请务必提供不少于指定重量(或表面积的样品以避免因样品不足补样而造成的时间损失。',
      TestItemON2: '测试方法首选为买家标准，若未提供买家，采用以下CIIC默认的参考测试方法。',
      TestItemON3: '参考测试方法含义为：参考标准测试方法的原理进行测试，相关的参数（如称样量、稀释倍数等）与标准测试方法相似但不一定完全相同。',
      TestItemON4: '是否接受并同意采用参考测试方法：',
      accept: '接受',
      objection: '如有异议请注明',
      SampleDetails: '样品详情信息',
      SampleDetailsON1: '普通样品保存期为30天；液体（如油漆、油墨、胶、膏体）、粉末样品保存期为出报告后1周，逾期销毁。',
      SampleDetailsON2: '待测物质浓度是否占样品总量的1%以上',
      SampleDetailsON3: '否 (待测物质浓度占样品总量1%以上时需使用其他测试方法，未注明讲造成测试结果的偏差）',
      SampleDetailsON4: '是否接受将样品分包到其他具有资格的公司？',
      SampleDetailsON5: '有否涉及法律纠纷？如有，请说明：',
      riskText: '送测的样品具有',
      processMode: '样品处理方式',
      inspectionDate: '已与CIIC代表确认具体日期',
      SampleReturn: '样品退回',
      RoHs: '1.RoHS测试',
      RoHssize: '最低样本量：2个',
      DEHP: '2.邻苯二甲酸酯',
      DEHPsize: '最低样本量： 20g',
      PAHS: '3.PAHs 多环芳香烃',
      PAHSsize: '最低样本量： 10g',
      battery: '4.电池指令',
      batterysize: '最低样本量：2个',
      WEEE: '5.WEEE 电子产品回收',
      WEEEsize: '最低样本量：整机',
      REACH: '6.REACH-SVHC',
      REACHsize: '最低样本量：整机',
      packing: '7.包装材料',
      packingsize: '最低样本量： 20g',
      other: '其他测试项目或要求，请注明：'
    },
    // 纺织品 鞋类 包
    textile: {
      SubdivisionCategory: '细分类别',
      TextileStandardClass: '纺织类别',
      SubdivisionError: '请选择细分类别',
      standardError: '请选择纺织标准类别',
      hintOne: '1. 请务必完整填写带*的项目；',
      hintTwo: '2.每份申请书对应一份测试报告 ；',
      reportForm: '报告/证书形式',
      reportCollection: '报告/证书领取',
      SampleName: '样品名称',
      SampleNameErr: '请输入样品名称',
      BrandName: '商标名称',
      BrandNameErr: '请输入商标名称',
      ModelNumber: '款号/型号',
      OrderNumber: '订单号',
      MaterialComposition: '材料成分',
      ProductGrade: '产品等级',
      ProductGradeErr: '请输入产品等级',
      RequestedDate: '送样量',
      RequestedDateErr: '请输入送样量',
      SecurityCategory: '安全类别',
      SecurityCategoryErr: '请选择安全类别',
      DenimProductCategory: '牛仔产品类别',
      WoolProductsCategory: '毛织产品类别',
      ResidualSampleProcessing: '验余样品处理',
      ResidualSampleProcessingErr: '请选择验余样品处理',
      supplier: '供应商',
      manufacturer: '生产商',
      purchaser: '买家',
      inspectionStandard: '检验依据/测试项目',
      inspectionStandardT: '检验依据',
      inspectionStandardErr: '服饰，床上用品，其他产品，请至少填写一项',
      TestItem: '检测项目',
      TestItemErr: '请至少填选择一项',
      Instruction: 'Care Instruction  (标签)',
      physicalProperties: '物理性能',
      colourFastness: '色牢度',
      functionality: '功能性',
      dimensionalStability: '尺寸稳定性',
      downProducts: '羽绒产品',
      chemicalProperties: '化学性能',
      ingredientsTest: '辅料测试',
      otherTest: '其他检测项目请注明',
      delivery: '报告寄送方式及要求',
      ReportSelection: '报告格式选择',
      ReportCopy: '报告副本',
      conclusion: '测试结论要求',
      reportsNeeded: '需要纸档报告',
      NoreportsNeeded: '不需要纸档报告',
      Need: '需要',
      Noneed: '不需要',
      evaluate: '评价',
      evaluatetext: '不评价（若委托方未指定则视为同意本机构选定）',
      exportingCountry: '出口到',
      exportingCountryErr: '请选择出口目的地',
      exportingCountryOth: '请填写其它出口国',
      producingCountry: '生产国',
      producingCountryOth: '请填写其它生产国',
      TestAgeRange: '测试年龄范围',
      TestAgeRangeErr: '请选择测试年龄范围',
      TestAgeRangeOth: '请输入测试年龄范围',
      scopeA: '是，要求测试年龄范围',
      scopeB: '否，测试年龄由CIIC决定',
      costume: '服饰',
      costumeOth: '请输入其它服饰',
      beddingsOth: '请输入其它床上用品',
      otherProductsOth: '请输入其它产品',
      beddings: '床上用品',
      otherProducts: '其他产品',
      standard: '检验标准',
      standardErr: '请输入其它检验标准',
      standardText: '检验标准（如果不选择，默认为由CIIC自行决定）',
      CarryBag: '背提包',
      luggageAndsuitcase: '旅行箱包',
      chemicalTest: '化学测试',
      OtherluggageAndbags: '其他箱包',
      OtherTestItems: '其他检测项目',
      finishedShoes: 'Shoe 成品鞋',
      insole: 'Midsole 中底',
      Upper: 'Upper 鞋面',
      Outsole: 'Outsole 大底',
      Heel: 'Heel 鞋跟',
      Lace: 'Lace 鞋带',
      require: '请按照测试要求选择检测项目'
    }
  },
  // 产品检测办理情况
  PTAPlist: {
    PaymentStatus: '付款状态',
    HandlingStatus: '办理状态',
    SubmissionTime: '提交申请时间',
    DetectionType: '检测类型',
    ApplicationCategory: '申请类别',
    ApplicationCode: '申请单号',
    QuotationM: '报价管理'
  },
  // GCC
  GCC: {
    ReceivingAddress: '自取地址:深圳市宝石路宝石科技园',
    placeOrdersOnline: '在线下单，快捷管理订单',
    productRelatedInformation: '产品相关信息',
    saveDraft: '保存为草稿',
    Title: '标题',
    PleaseInput: '请输入',
    PleaseSelect: '请选择',
    ProductNameCN: '产品名称（中文）',
    ProductNameEN: '产品名称（英文）',
    productCategory: '产品类别',
    ProductNameENtips: '请输入产品名称',
    sampleQuantity: '样品数量',
    SampleReturnOrNot: '样品是否返回',
    ModelSpecificationCN: '型号规格(中文)',
    ModelSpecificationtips: '请输入型号规格',
    ModelSpecificationEN: '型号规格(英文)',
    ProductSize: '产品尺寸',
    TestStandardVersionNumber: '测试标准/版本号',
    TechnicalParameter: '技术参数',
    TestModeOfTheProduct: '产品的测试模式',
    DoesTheProductApplyingForCertificationHaveACCCWSCTCBCertificate: '申请认证的产品是否有CCC/CIIC/CB证书 ',
    CCCWSCTCBCertificateNumber: 'CCC/CIIC/CB证书编号',
    NameOfTestingInstitution: '检测机构名称',
    Brand: '商标',
    CustomsCode: '海关编码',
    NewApplicationForCertification: 'GCC认证 新申请',
    uploadAttachment: '上传附件',
    attachment: '附件',
    uploadtips: '点击上传   原理图 / PCB图 / 产品说明书 / 变压器规格书 / 型号差异说明 / 合同 / 营业执照/其他',
    fileType: '文件类型',
    fileName: '文件名称',
    Siez: '大小',
    State: '状态',
    Back: '上一步',
    submit: '提交',
    HistoryData: '选择历史数据',
    Remake: '备注',
    Operate: '操作',
    textone: '每个上传的附件大小不超过30M;否则将无法正常提交',
    texttwo: '支持的文件类型为:jpg、gif、png、bmp、doc、docx、xlsx、xls、pdf、txt、zip、rar。',
    Yes: '是',
    No: '否',
    NoWSCT: '否(CIIC自行处理)',
    certificationProject: '认证项目',
    detectionType: '（检测类型）',
    cProjectError: '请选择认证项目',
    allParties: '各方基本信息',
    Applicant: '申请商',
    applicant: '委托人（持证方）',
    manufacturer: '制造商',
    factory: '生厂商',
    Client: '委托人',
    Importer: '进口商',
    companyCN: '公司名称(中文)',
    company: '公司名称',
    companyENError: '请输入公司名称',
    companyEN: '公司名称(英文)',
    siteCN: '地址(中文)',
    siteENError: '请输入地址',
    siteEN: '地址(英文)',
    contactName: '联系人',
    contactNameError: '请输入联系人',
    telephone: '电话',
    telephoneError: '请输入电话',
    telephone11Error: '不能少于四位',
    telephone12Error: '只能由数字、短划线(-)和加号(+)组成，4~20个字符之间',
    email: '邮箱',
    emailError: '请输入正确的邮箱',
    fax: '传真',
    chooseHistoricalData: '选择历史数据',
    identicalApplicant: '同委托人',
    SameManufacturer: '同制造商',
    else: '其他',
    confirm: '确认',
    close: '关闭',
    screen: '筛选',
    resetting: '重置',
    serviceRequest: '服务要求',
    serviceRequestErr: '请输入服务要求其它时间',
    reportproductType: '请选择产品类型',
    // 用户条款
    userTerms: '用户条款',
    companyAddress: '，地址，',
    ourCompany: '我司，',
    userTermsStated: '，在此特别申明，本次申报的产品不受召回产品的影响，也不是不合格品或假冒伪劣产品，我们对所有申报信息的真实性承担责任。 同时，我们也深知进口国对这些产品进口的监管要求并承诺遵守这些要求。',
    userTermsInfo: '提交信息后，CIIC会尽快受理申请单并且邮件联系您，请确认是否此邮箱',
    submitSuccess: '提交成功',
    submitError: '提交失败',
    SaveSuccess: '自动保存成功',
    SaveError: '自动保存失败',
    DraftsSuccess: '保存草稿成功',
    DraftsError: '保存草稿失败',
    titleTips: '就是您给自己的申请起的名字，便于您查询和区分不同的申请，两个申请的申请标题不能重复。 您可以采用便于自己统计和查询的方式命名，不宜太长，举例： 1）公司名称缩写-产品缩写-编号；2） 商标-日期-编号 。',
    CertificationCategory: '认证类别',
    CertifiedProduct: '认证产品',
    HSCode: '海关编码',
    TargetMarket: '目标市场',
    relatedAttachments: ' 相关附件',
    RequestAdditionalInformation: '申请附加信息',
    productSize: '产品尺寸',
    testStandard: '测试标准/版本号',
    technicalSpecification: '技术参数',
    testingMode: '产品的测试模式',
    certificateMode: '申请认证的产品是否有CCC/CIIC/CB证书',
    CertificateNumber: 'CCC/CIIC/CB证书编号',
    CertificateNumberError: '请输入CCC/CIIC/CB证书编号',
    detectionMechanism: '检测机构名称',
    detectionMechanismError: '请输入检测机构名称',
    InvoiceInformation: '发票信息',
    ManageInvoiceInformation: '管理发票信息',
    CertificateMailingInvoiceInformation: '证书/发票邮寄信息',
    ManageMailing: '管理邮寄信息',
    invoiceType: '发票类型',
    PleaseSelectInvoiceType: '请选择发票类型',
    invoiceTitle: '发票抬头（公司名称）',
    site: '地址',
    bank_name: '开户行',
    bank_account: '银行账号',
    taxpayer: '纳税人识别号',
    back: '开户银行及账号',
    phone: '联系电话',
    mobile: '手机号码',
    zipCode: '邮编',
    mailingAddress: '邮寄地址',
    addInvoiceInformation: '新增发票信息',
    MailingOrReceivingCertificatesAndInvoices: '证书/发票邮寄或领取',
    // 填写认证申请书
    certificationCategory: '认证类别',
    certificationCategoryName: 'GCC  认证  新申请',
    selectProduct: 'CIIC在下列产品范围内能够从事认证业务，请选择您的产品',
    selectProductTips: '请选择您的产品',
    businessScope: '业务范围',
    warHis: '请选择历史数据',
    companyName: '公司名称',
    AllParties: '各方基本信息',
    product: '产品相关信息',
    usreTermsInfo2: '能联系到您，如不是，请在下方填写，多个邮箱以“；”隔开。'
  },
  // 委托检验
  entrustedInspection: {
    contactPhone: '联系电话',
    checkAddress: '检验地址',
    contractNo: '合同号',
    countryCodeOfProduct: '产品销售国代码',
    inputAddress: '请输入详细地址',
    verificationDecription: '检验描述',
    inputDescription: '请输入检验描述',
    selectCountryCode: '请选择产品销售国代码',
    selectStandard: '请选择标准',
    choiceCriterion: '选择标准',
    PreInspectionDate: '预检日期',
    entrustedBaseInfo: ' 填写委托检验方基本信息',
    entrustedBaseInfo2: ' 委托检验方基本信息',
    inspectionServices: '检验服务',
    pleaseSelectServer: '选择服务',
    viewContract: '查看委托检验合同'
  },
  formLength: {
    maxLength255: '最大长度为255字符',
    maxLength500: '最大长度为500字符',
    maxLength100: '最大长度为100字符'
  },
  entrustedBusiness: {
    entrustedInspection: '委托检验',
    entrustedDescribe: '委托检验是指企业为了对其生产、销售的产品质量监督和判定，委托具有法定检验资格的检验机构进行检验。检验机构依据标准或合同约定对产品检验，出具检验报告给委托人，检验结果一般仅对来样负责。',
    entrustedDescribeTwo: 'CIIC贸易及机构服务将凭借专业的团队，丰富的产品符合性评定经验，协助并为广大出口企业提供广泛、高效和量身定制的解决方案，以确保出口商的货物符合进口国适用的标准和法规要求, 助您的产品顺利完成委托检验。',
    mainProcesses: '主要流程',
    processessOne: '向检验和认证机构(如CIIC)提供信息以供审查并预约检验。',
    processessOneDescribe: '就提供的具体信息而言，不同国家和出口国之间可能有一些差异，但总体上是相似的。例如:出口尼日利亚:委托书(机构提供模板供客户填写)、装箱单、、检测报告(具体检测报告接受什么标准，如何与机构确定报告有效期)、表格；出口肯尼亚:委托书(机构提供给客户填写的模板)、装箱单、、检测报告(具体检测报告接受什么标准，如何与机构确定报告有效期)、IDF。',
    processessTwo: '业务受理。',
    processessTwoDescribe: '确认协议书内容，样品状态，签字，划价，财务收费，开具发票，等信息，前往指定的地址依据协议书中签订的内容进行检验委托报告签发。',
    processessThree: '检验完成后，由相关的检验部门编制检验报告，经审核后，提交批准人签发，用户可以在CIIC客户端中下载电子版的报告，需要邮寄的，业务管理部门可以代办邮递手续；如对报告有异议的及时联系CIIC相关业务部门，我们会及时给予处理。'
  },
  // 通知
  notice: {
    // 送样通知
    serialNumber: '申请流水号',
    certificationCategory: '认证类别',
    sendingTime: '发送时间',
    notificationNumber: '通知编号',
    applicationNo: '申请编号',
    applicationTitle: '申请标题',
    applicationCategory: '申请类别',
    bailor: '委托人',
    factory: '生产厂',
    productName: '产品名称',
    sender: '发送人',
    sendingDepartment: '发送部门',
    currentStage: '当前阶段',
    // 收样回执通知
    title: '标题',
    read: '是否已读',
    institutionOrdepartment: '发送机构/部门',
    type: '类型'
  },
  // 缴费记录
  paymentRecord: {
    // 未缴费记录
    title: '标题',
    preferentialPrice: '优惠后CIIC收费总额',
    settlementDepartment: '结算部门',
    settlementDate: '发费用结算单日期',
    // 我的汇款记录
    remittanceDate: '汇款时间',
    addRemittanceInformation: '新增汇款信息',
    remitter: '汇款人',
    remittanceAmount: '汇款单金额',
    currency: '币种',
    deleteTips: '确定删除当前记录吗？',
    totalRemittanceAmount: '汇款总金额',
    dateOfRemittance: '汇款日期',
    contacts: '联系人',
    contactPhone: '联系电话',
    postalCode: '邮政编码',
    address: '地址',
    remark: '备注',
    remittanceBasicInformation: '汇款基本信息',
    settlementList: '费用结算单列表',
    applicationNo: '申请编号',
    bailor: '委托人',
    factory: '生产厂',
    money: '金额',
    acceptanceDepartment: '受理部门',
    chargingStatus: '收费状态',
    valid: '本单是否有效',
    userPlaceholder: '用户名',
    amountCurrency: '金额币种',
    telephone: '电话',
    telephonePlaceholder: '请输入电话,例如:010-89718191',
    postcode: '邮编',
    remittanceBtn: '导入汇款信息',
    download: '下载',
    remittanceTips: '汇款信息模板',
    applicationNoBtn: '填写更多申请编号',
    applicationNoTips: '填写申请编号时，请填写完整的申请编号',
    remittanceInformation: '汇款信息',
    remitterTips: '请输入汇款人',
    dateOfRemittanceTips: '请选择汇款日期',
    contactsTips: '请输入联系人',
    contactPhoneTips: '请输入电话',
    // 费用通知
    serialNumber: '申请流水号',
    applicationTitle: '申请标题',
    notificationTime: '通知时间',
    applicationCategory: '申请类别',
    certificationCategory: '认证类别',
    productName: '产品名称',
    currentStage: '当前阶段',
    settlementType: '结算类型',
    paidExpenses: '已交费用',
    institutionPreferentialPrice: '检测机构收费总额优惠后',
    wsctPreferentialPrice: 'CIIC收费总额优惠后',
    totalPreferentialPrice: '总费用优惠后',
    feeNotificationTime: '收费通知时间',
    immediateRemittance: '立即汇款'
  },
  // 无线通信
  communication: {
    scopeOfCertification: '认证范围',
    selectTypeTip: '在下列产品范围内能够从事认证业务，您选择的产品是（单选项）',
    selectScopeTitle: '选择认证范围',
    selectScopeText: '有关该类产品申请VOC认证的相关文件（请仔细阅读并按相关要求进行操作）',
    certificateHolderTitle: '1.申请证书持有人（带',
    certificateHolderTitle2: '为必填项）',
    applicant: '申请人',
    manufacturer: '制造商',
    factoryInformation: '工厂信息',
    factoryName: '工厂名称',
    coApplicant: '同申请人',
    coManufacturer: '同制造商',
    contactPerson: '联络人',
    website: '网址',
    tradeLicense: '营业执照号码',
    invoiceMailingAddress: '发票信息及寄送地址',
    productParameter: '产品参数附件',
    productInfo: '产品信息',
    txtentOfTesting: '测试范围',
    productName: '产品名称',
    mainNumber: '主测型号',
    addingModel: '系列型号',
    tradeName: '商标',
    highestOperating: '最高工作频率(IC，晶振，谐振···等取频率最大值)',
    EUTOperatingTemperature: 'EUT Operating Temperature Range',
    EUTOperatingVoltage: 'EUT Operating Voltage Range',
    ratedVoltage: '额定电压',
    ratedPower: '额定功率',
    ratedCurrent: '额定电流',
    input: '输入',
    output: '输出',
    CEHardwareVersion: '硬件版本号(适用于 CE)',
    CESoftwareVersion: '软件版本号(适用于 CE)',
    adaptorMN: '适配器型号',
    batteryMN: '电池型号',
    adaptorRatings: '适配器参数',
    batterySpecification: '电池规格',
    password: '工程密码(适用于手机，平板产品)',
    protectionAgainst: '防触电等级',
    additionalInfo: '额外的信息，请加上附件：',
    additionalInfoText1: '1、点击“+”按钮，选择上传附件。',
    additionalInfoText2: '2、每次上传附件的大小不能超过10M，最多上传5个文件。',
    additionalInfoText3: '3、支持的文件类型为jpg、gif、png、bmp、doc、docx、xlsx、xls、pdf、txt、zip、rar。',
    internationalScope: '适用国际标准',
    EUStandard: '欧盟、非洲东南亚国家标准',
    USACanadaStandard: '美国、加拿大美洲标准',
    othersStandard: '其他国家特定标准',
    other: '其他',
    subcontract: '分包',
    isAcceptTest: '本公司不接受CIIC以外的测试报告（如未选择，视为接受）',
    handlingInstruction: '样品处理方式',
    fetchByYourself: '自取',
    toBeCollected: '快递到付',
    toBeDestroyed: '由CIIC秘密销毁',
    destroyedReminder: '* 如未选择，视为接受由CIIC销毁样品。如选择退样，但样品超过12个月后不取样或不接受快递到付，则按照放弃处理，由CIIC销毁。',
    specialVATInvoice: '增值税专用发票',
    ordinaryVATInvoice: '增值税普通发票',
    proFormaInvoice: '形式发票',
    taxpayerNumber: '纳税人识别号',
    invoiceType: '发票类型：',
    invoiceHeader: '发票抬头(发票名称须与付款名称一致)',
    bankCode: '银行账号',
    bankName: '开户银行:',
    receiver: '收件人',
    applyingItem: '申请项目',
    newApplication: '新申请',
    modification: '修改',
    specifyReport: '请提供原始报告 / 证书号码',
    standardUpdate: '标准更新',
    alternate: '报备零部件',
    alternateModels: '报备型号',
    grandeeCode: '等级代码',
    productCode: '产品代码',
    burundiRpc: 'Burundi RPC',
    companyNo: 'Company No',
    CertifiedProduct: '认证产品',
    inputAddress: '请输入公司地址',
    inputAddressEn: '请输入公司地址（英文）',
    inputContact: '请输入联络人',
    inputUrl: '请输入网址',
    inputTradeLicense: '请输入营业执照号码',
    pleaseInput: '请输入内容',
    pleaseInputCategory: '请选择认证产品',
    pleaseInputScope: '请选择认证范围',
    pleaseCompleteForm: '请完善表单信息',
    pleaseInputCertificate: '请选择证书类型',
    pleaseSelectSampleHand: '请选择样品处理方式',
    pleaseSelectApplyType: '请选择申请类型',
    pleaseSelectStandard: '请选择适用国际规范',
    upload5Attachments: '提示：最多上传五个附件！',
    filesTips: '如有电子版资料，例如额定值或技术参数的文件，可以作为申请书的电子附件上传',
    pleaseSelectInvoiceType: '请选择发票类型',
    viewContract: '查看检测认证合同',
    importVOCApplication: '导入VOC申请数据',
    selectVOCData: '请选择VOC申请数据'
  },
  thirdLogin: {
    scanCode: '扫一扫',
    scanCompleteRegist: '扫描完成注册，即可登录',
    quickLogin: '快速登录',
    registInfo: '注册信息完善',
    alipayLogin: '支付宝登录',
    thirdPartyLogin: '第三方登录'
  }
}
