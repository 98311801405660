import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import tool from '@/utils/tool'
import Layout from '@/layout/index.vue'
import LayoutNoLeft from '@/layout/index-noleft.vue'
import customsClearance from './modules/customsClearance'
import productTesting from './modules/productTesting'
import entrustedInspection from './modules/entrustedInspection'
import communication from './modules/communication'
import { useMainStore } from '@/stores/main'
import { storeToRefs } from 'pinia'
import { ElMessage } from 'element-plus'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: '/customsClearance/workBench',
    children: [
      customsClearance,
      productTesting,
      entrustedInspection,
      communication
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/index.vue')
  },
  {
    path: '/RegisteredChannel',
    name: 'RegisteredChannel',
    component: () => import(/* webpackChunkName: "login" */ '@/views/RegisteredChannel/index.vue')
  },
  {
    path: '/LoginChannel',
    name: 'LoginChannel',
    component: () => import(/* webpackChunkName: "login" */ '@/views/LoginChannel/index.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../../src/layout/404.vue')
  },
  {
    path: '/policyInfo',
    name: 'policyInfo',
    component: LayoutNoLeft,
    children: [
      {
        path: '',
        name: 'policyInfo',
        component: () => import(/* webpackChunkName: "policyInfo" */ '../views/policyInfo/index.vue')
      }
    ]
  },
  {
    path: '/myProfile',
    name: 'myProfile',
    component: LayoutNoLeft,
    children: [
      {
        path: '',
        name: 'myProfileInfo',
        component: () => import(/* webpackChunkName: "myProfileInfo" */ '@/views/myProfile/index.vue')
      }
    ]
  },
  {
    path: '/publicFile',
    name: 'publicFile',
    component: LayoutNoLeft,
    children: [
      {
        path: '',
        name: 'publicFile',
        component: () => import(/* webpackChunkName: "policyInfo" */ '../views/publicFile/index.vue')
      }
    ]
  },
  {
    path: '/messageList',
    name: 'messageList',
    component: LayoutNoLeft,
    children: [
      {
        path: '',
        name: 'messageList',
        component: () => import(/* webpackChunkName: "messageList" */ '../views/messageList/index.vue')
      }
    ]
  },
  {
    path: '/messageDetails',
    name: 'messageDetails',
    component: LayoutNoLeft,
    children: [
      {
        path: '',
        name: 'messageDetails',
        component: () => import(/* webpackChunkName: "messageDetails" */ '../views/messageList/messageDetails.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path === '/RegisteredChannel' || to.path === '/LoginChannel') {
    next()
    return
  }
  // 保存路由参数以防到登录页面丢失
  const queryData = JSON.parse(JSON.stringify(to.query))
  const token = tool.cookie.get('TOKEN_frontend')
  // 路由改变改变菜单默认选中
  const store = useMainStore()
  const { activeMenu, activePage, filterMenu, breadcrumb } = storeToRefs(store)
  // todo 如果是第三方登录，route.query判断有auth_code，调接口获取token并缓存，跳转到主页不跳转到登录页面
  // 调接口 await
  // return
  if (token) {
    // 一级菜单
    activeMenu.value = to.path.split('/')[1]
    next()
    if (!filterMenu.value || !filterMenu.value.length) return
    // 面包屑
    for (let r = 0; r < filterMenu.value.length; r++) {
      const element: any = filterMenu.value[r]
      if (element.path === to.path || element.name === to.name) {
        breadcrumb.value = element.breadcrumbList || []
        break
      }
    }
  } else {
    to.path === '/login' ? next() : next({ path: '/login', query: queryData })
  }
})
// 更新包后无法获取到最新的代码，需要特殊处理，重新加载页面
router.afterEach((to, from, failure) => {
  if (failure) {
    // ElMessage.warning('failure')
    // console.log('failure:', failure)
    location.reload()
  }
})
// 更新包后路由访问错误，重新加载页面,加载最新的chunk资源
router.onError(() => {
  // ElMessage.warning('onError')
  location.reload()
  // const pattern = /Loading chunk/g
  // const isChunkLoadFailed = error.message.match(pattern)
  // if (isChunkLoadFailed) {
  //   console.log('isChunkLoadFailed')
  //   location.reload()
  // }
})

export default router
