
// 导入 Axios 请求
import { createAxios } from '@/utils/request'
const request = createAxios()
const urlPrefix = process.env.VUE_APP_URL_CHANNEL
const urlPrefixBase = process.env.VUE_APP_URL_BASE
const requestchannel = createAxios(undefined, urlPrefix)
const requestBase = createAxios(undefined, urlPrefixBase)
// get 请求

export default {
  // 获取验证码
  captcha: (params: any): Promise<any> =>
    request.get('captcha', { params }),
  // 登录
  getlogin: (data: any): Promise<any> =>
    request.post('login', { ...data, website_source: 3 }), // 1-WSCT世标 2-GSTI环标 3-CIIC中创
  // 登出
  logout: (data: any): Promise<any> =>
    request.post('logout', data),
  // 获取短信验证码
  getsms: (data: any): Promise<any> =>
    request.post('sms/code', data),
  // 获取验证码 手机/邮箱
  getCode: (data: any): Promise<any> =>
    request.post('/send/code', data),
  // 验证验证码 手机/邮箱
  verifyCode: (data: any): Promise<any> =>
    request.post('/verify/code', data),
  // 手机短信登录
  smsLogin: (data: any): Promise<any> =>
    request.post('sms/login', { ...data, website_source: 3 }),
  // 三方登录网址
  thirdLoginUrl: (data: any): Promise<any> =>
    request.post('third/redirect-url', data),
  // 三方登录
  thirdLogin: (data: any): Promise<any> =>
    request.post('third/login', { ...data, website_source: 3 }),
  // 注册
  register: (data: any): Promise<any> =>
    request.post('register', { ...data, website_source: 3 }),

  // 渠道商注册
  Channelregister: (data: any): Promise<any> =>
    requestchannel.post('/customer', data),
  // 渠道商注册字典
  getDictionaryJ: (data: any, lang:any): Promise<any> =>
    requestBase.get(`/common/getDictionaryList?slugs=${data}&langId=${lang}`),
  // 渠道商注册国家
  getDictionary: (data: any): Promise<any> => {
    return requestchannel({
      method: 'GET',
      url: '/country',
      params: data
    }
    )
  },
  // 渠道商注册地区
  getDictionarylist: (data: any): Promise<any> => {
    return requestchannel({
      method: 'GET',
      url: '/area/list',
      params: data
    }
    )
  },
  // 渠道商获取区号
  getZonelist: (data: any): Promise<any> => {
    return requestBase({
      method: 'GET',
      url: '/common/getCountrysList',
      params: data
    }
    )
  },
  // 通过地址获取省市区
  getAddress: (data: any): Promise<any> => {
    return requestBase({
      method: 'GET',
      url: '/common/optAddress',
      params: data
    }
    )
  },
  // 接入企查查
  getCompany: (data: any): Promise<any> => {
    return requestBase({
      method: 'GET',
      url: '/common/getFuzzyList',
      params: data
    }
    )
  },
  // 修改密码
  resetPassword: (data: any): Promise<any> =>
    request.post('reset-password', data),
  // 忘记密码
  forgotPass: (data: any): Promise<any> =>
    request.post('forgot/pass', { ...data, website_source: 3 }),
  forgotCode: (data: any): Promise<any> =>
    request.post('forgot/code', data),
  newPass: (data: any): Promise<any> =>
    request.post('forgot/new/pass', data),
  // 切换语言
  switchLang: (data: any): Promise<any> =>
    request.post('switch-lang', data),
  // 字典查询
  dictionary: (data: any): Promise<any> =>
    request.post('query-dictionary', data),
  // 获取菜单信息
  getPermission: () => {
    return request({
      method: 'GET',
      url: '/permission'
    })
  },
  // 获取国家区域号码列表
  getCountryCode: () => {
    return request({
      method: 'GET',
      url: '/country/zone'
    })
  }
}
